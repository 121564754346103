<template>
  <div class="row m-0 fixed">
    <div class="col-10 col-sm-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5 p-0 card_item position-relative">
      <div class="row mx-0">
        <div class="col-12 p-0">
          <div class="row m-0">
            <div class="col-12 p-0">
              <div class="row mx-0 h-100 justify-content-center" v-if="cart.length > 0">
                <div class="col-12 p-3 text-center position-relative">
                  <span class="card_heading bold">Cart Total - {{ currency }} {{ total }}</span>
                  <ios-close-icon class="inline-icon position-absolute top-50 end-0 translate-middle cursor" @click="$emit('close')" />
                </div>
                <div class="col-12 p-0 border-top max70 overflow-auto">
                  <div class="row m-0 justify-content-center">
                    <div class="col-12 p-0">
                      <CartItem v-for="item in oldItems" :key="item" :item="item" />
                    </div>
                    <transition name="slideIn">
                    <div class="col-12 p-0" v-if="showNewItems">
                      <CartItem v-for="item in newItems" :key="item" :item="item" />
                    </div>
                    </transition>
                  </div>
                </div>
                <div class="col-12 p-0 align-self-end">
                  <div class="row m-0 background-green p-md-3 py-3 justify-content-center">
                    <div class="col-6 pe-1 ps-md-4">
                      <Button color="green_light" width="100" btnText="Keep Browsing" icon2="arrow" @buttonClicked="$emit('close')" class="px-md-2 px-1">
                        <template v-slot:iconStart>
                          <IconArrowBack />
                        </template>
                      </Button>
                    </div>
                    <div class="col-6 ps-1 pe-md-4">
                      <Button color="red" width="100" btnText="Checkout" icon="arrow" @buttonClicked="goCheckout" class="px-md-2 px-1">
                        <IconArrowForward color="white" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-0 h-100 justify-content-center" v-else>
                <div class="col-12 p-3 text-center card_heading">
                  No items in your cart
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    CartItem: defineAsyncComponent(() => import('../components/CartItemAddPopup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Add Cart Popup',
  data () {
    return {
      showNewItems: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.showNewItems = true
    }, 700)
  },
  computed: {
    ...mapGetters([
      'cart', 'newCart'
    ]),
    currency () {
      if (this.cart.length > 0) {
        return this.cart[0].currency || ''
      }
      return process.env.VUE_APP_CURRENCY_SYMBOL
    },
    total () {
      let ret = 0
      this.cart.forEach(item => {
        ret += Number(item.amount)
      })
      return parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    newItems () {
      return this.cart.filter(item => {
        if (item.projectId !== this.newCart[0].projectId) {
          return false
        }
        return true
      })
    },
    oldItems () {
      return this.cart.filter(item => {
        if (item.projectId === this.newCart[0].projectId) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    goCheckout () {
      this.$router.push('/checkout/0')
    }
  }
}
</script>

<style scoped>
.fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  justify-content: center;
  align-items: center;
}
.position_absolute {
  top: 0.5rem;
  right: 0.5rem;
}
.card_item {
  background-color: rgb(244, 244, 244);
  border-radius: 13px;
}
.max70 {
  max-height: 70vh;
}
.abs {
  position: absolute;
  width: 600%;
  left: -250%;
  max-height: 50vh;
  overflow-y: auto;
  z-index: 2;
}
.card_title {
  font-family: "Quicksand", Sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
}
.card_heading {
  font-size: 17px;
  color: var(--green-color);
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.slideIn-enter-active {
  transition: all 0.5s ease-out;
}
.slideIn-leave-active {
  transition: all 0.5s ease-in;
}

.slideIn-enter-from,
.slideIn-leave-to {
  opacity: 1;
  transform: translateX(100%);
}
</style>
